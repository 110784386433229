<template>
  <AppLayout>
    <template v-slot:appContent>
      <div>
        <div class="product-setting-wrapper">
          <div class="accordion" id="productAccordion">

            <div class="card">
              <div class="card-header" id="headingProductCodePrefix">
                <button class="btn btn-block text-left w-100" type="button" data-toggle="collapse"
                        data-target="#collapseProductCodePrefix" aria-expanded="false" aria-controls="collapseProductCodePrefix">

                  <span class="text-capitalize product-setting-title">Product Code Prefix</span>

                </button>
              </div>
              <div id="collapseProductCodePrefix" class="collapse" aria-labelledby="headingProductCodePrefix" data-parent="#productAccordion">
                <div class="card-body">
                  <SettingProductCodePrefix :product-code-prefix="this.productCodePrefix"/>
                </div>
              </div>
            </div>

            <div class="card">
              <div class="card-header" id="headingProductCodeSuffix">
                <button class="btn btn-block text-left w-100" type="button" data-toggle="collapse"
                        data-target="#collapseProductCodeSuffix" aria-expanded="false" aria-controls="collapseProductCodeSuffix">

                  <span class="text-capitalize product-setting-title">Product Code Suffix</span>

                </button>
              </div>
              <div id="collapseProductCodeSuffix" class="collapse" aria-labelledby="headingProductCodeSuffix" data-parent="#productAccordion">
                <div class="card-body">
                  <SettingProductCodeSuffix :product-code-suffix="this.productCodeSuffix"/>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>

    </template>
  </AppLayout>
</template>

<script>
import AppLayout from '@/layouts/backEnd/AppLayout.vue';
import Loader from "@/components/backEnd/mixins/Loader";
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
import {
  mapActions,
  mapGetters
} from 'vuex';
import SettingProductCodePrefix from "@/views/backEnd/settings/products/includes/SettingProductCodePrefix";
import SettingProductCodeSuffix from "@/views/backEnd/settings/products/includes/SettingProductCodeSuffix";

export default {
  name: "SettingProductList",
  components: {
    AppLayout,
    SettingProductCodePrefix,
    SettingProductCodeSuffix,


  },
  mixins: [ShowToastMessage, Loader],
  data() {
    return {
      getSettingsParams: {
        type: ["product"],

        key: [
            "product_code_prefix",
            "product_code_suffix",
        ],
      },
      currentState: {},
      frontEndErrors: [],

    }

  },

  computed: {
    ...mapGetters({
      productCodePrefix: "appSettings/settingProductProductCodePrefix",
      productCodeSuffix: "appSettings/settingProductProductCodeSuffix",

    }),

  },

  methods: {
    ...mapActions({

      getSettings: "appSettings/getSettings",
      putSetting: "appSettings/putSetting",

    }),
    async getSettingList() {
      await this.getSettings(this.getSettingsParams);
    },

  },
  async mounted() {
    await this.loader(true);
    await this.getSettingList();
    await this.loader(false);
  },

}
</script>

<style>
.product-setting-wrapper .accordion .card {
  overflow: visible !important;
}

.product-setting-title {
  font-size: 16px;
  color: rgb(44, 44, 44);
}
</style>
